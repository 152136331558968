#footer {
  .footer-bottom {
    .loyalty_content.hidden {
      display: none;
    }
  }
  .footer-trustmark {
    float: none;
    width: 100px;
    margin: 0 auto;
  }
  .social-links {
    height: 25px;
    width: 100%;
    margin: 15px auto;
    text-align: center;
  }
  .footer-top-mobile-v1 {
    .social-links a {
      float: none;
      margin: 0 15px;
    }
  }
}

// Contact us Page
.contact_us {
  form {
    .question_container {
      select {
        option {
          &:nth-child(4),
          &:nth-child(7) {
            display: none;
          }
        }
      }
    }
  }
}

.device-mobile {
  .store-locator-mobile-v1 {
    .map_container {
      width: 100%;
      .scroll-overlay {
        background: none !important;
        width: auto !important;
      }
      .map_canvas {
        width: 100%;
        .gm-style {
          .gm-style-iw {
            padding: 0 10px 5px;
          }
        }
      }
    }
    .button {
      line-height: 35px;
    }
  }
  .customer-care-list {
    .chat,
    .sms {
      display: none;
    }
  }
}

.mpp-product {
  .product-info {
    width: 51%;
  }
}

.adv_cal_cap {
  .adv-cal-cap-container {
    .adv-cal-cap-popup {
      .popup-content-wrapper {
        .offer-details__controls {
          .btn-wrapper {
            .button {
              float: $rdirection;
              margin-top: 0;
              width: 48%;
              background-color: $color-white;
              color: $color-black;
              :hover {
                background-color: $color-white;
                color: $color-black;
              }
            }
            .button:nth-child(even) {
              float: $ldirection;
              width: 48%;
              color: $color-white;
              background-color: $color-black;
              :hover {
                background-color: $color-black;
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }
}

html[lang='zh-hk'] {
  .header-gnav-navigation {
    .text-link--style-2-bold {
      font-weight: 700;
    }
  }
}

.header-gnav-navigation {
  a[href='#'] {
    text-decoration: none;
  }
}
