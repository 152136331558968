#ot-sdk-btn {
  &.ot-sdk-show-settings {
    font-family: $base-bold-font-family;
  }
}

html[lang='en-HK'] {
  #ot-sdk-btn {
    &.ot-sdk-show-settings {
      font-size: 14px !important;
      @media #{$cr19-medium-up} {
        font-size: 22px !important;
      }
    }
  }
}
