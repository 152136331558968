$color-cl-dark-grey: #121212;

// Welcome sign up pop up
.device-mobile {
  .popup-offer-v1 {
    .outer-wrap {
      width: 100% !important;
      left: 0 !important;
      &.signup {
        height: 353px;
        padding: 20px 20px 15px !important;
      }
    }
    .signup {
      @include swap_direction(padding, 20px 20px 0 0 !important);
      h2 {
        text-align: center;
        @include swap_direction(padding, 0 15px);
      }
      .subheading {
        p {
          text-align: center;
          @include swap_direction(padding, 0 15px);
        }
      }
      .signup-forms {
        form {
          .form-submit-wrap {
            @include swap_direction(padding, 0 15px);
            margin: 0;
            .form-text {
              width: 100%;
            }
            input.button[type='submit'] {
              margin-left: 0;
            }
          }
          .form-messages {
            .error {
              @include swap_direction(padding, 0 15px);
            }
          }
        }
        .popup-offer-msg {
          margin: 8px 20px 0 20px;
        }
      }
    }
  }
  .ingredients_container {
    .ingredients {
      &__header {
        font-size: 22px;
        font-weight: normal;
      }
    }
  }
}

#footer {
  .footer-bottom {
    .block {
      .field-mobile-menu {
        .first {
          .switch-lang-link-ch {
            &.active {
              font-weight: bold;
            }
          }
        }
      }
    }
    ul.menu {
      li.item-3,
      li.item-5 {
        border-#{$ldirection}: 1px solid $color-cl-soft-black !important;
        padding-#{$ldirection}: 10px !important;
      }
    }
  }
}

#site_down {
  #header {
    margin-top: 42%;
    font-size: 40px;
  }
  #TheSiteisTemporarily {
    font-size: 23px;
  }
  #girl {
    top: 152px;
  }
}

#main.single {
  .panel {
    select,
    input {
      height: calc(3em - -2px);
    }
  }
  .uneditable-input {
    height: calc(3em - -2px);
  }
}

:lang(zh-hant-e-HK) {
  .product-subheading {
    display: none;
  }
}

:lang(en-e-HK) {
  .product-subline {
    display: none;
  }
}

.three-step-shopbystep-mobile-v1 {
  .product-list {
    .product-info {
      .product-name {
        max-height: 100px;
      }
    }
  }
}

.mobile_mpp {
  .mpp-product {
    min-height: 180px;
  }
}

.three-step-formatter-rows {
  .mpp_product {
    .mpp-threestpmob {
      margin-bottom: 10px;
      max-height: 100px;
    }
  }
}

.field-mobile-menu {
  ul {
    li {
      &.parent {
        .checkout-icon {
          background: url(/media/export/cms/icon-bag.png) no-repeat scroll 0 0 transparent;
          display: inline-block;
          height: 28px;
          margin-left: 3px;
          margin-top: 3px;
          width: 22px;
        }
      }
    }
  }
}
/**
overriding font family for chinese site
**/
:lang(zh-HK) {
  #clinique-cny {
    &.main-clinique-cny {
      font-family: $base-font-family;
    }
  }
}

#content {
  &.contact_us {
    .submit_btn {
      .bgdisable {
        background-color: $color-grey;
        pointer-events: none;
      }
    }
  }
}

.elc-search-typeahead-count {
  display: none;
}

.pr-review {
  .pr-rd-flag-review-btn {
    &::after {
      width: auto !important;
    }
  }
}
