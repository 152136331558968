.appt-book {
  .appointment-select {
    .appointments-container {
      .sign-in-container {
        .sign-in-form-container {
          .tooltip {
            text-decoration: none;
            font-weight: normal;
          }
          .social-login__email-opt-in {
            #facebook__email-opt-in-register-panel {
              height: 17px;
              width: 25px;
            }
            .social-login__opt-in-label {
              margin-top: 0px !important;
              padding-left: 3px;
            }
          }
        }
      }
      .book-appt-container {
        .registration__terms-text,
        .registration__sms-list-text,
        .registration__email-list-text {
          padding-left: 3px;
        }
        .mobile-phone-fieldset {
          .appt_phone_code {
            float: left;
            height: 100%;
            width: 25%;
          }
          .appt-book-mobile {
            float: right;
            width: 74%;
          }
        }
      }
    }
  }
  .appt-book-page-header {
    .appt-book-page-header-content {
      height: 80% !important;
      &__title {
        @include breakpoint($medium-l) {
          h2 {
            margin-left: 0 !important;
            font-size: 30px !important;
          }
          h4 {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}
