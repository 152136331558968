.clinical-reality__mpp--product {
  .product-image {
    &:hover {
      .product-add-to-bag {
        display: none;
        @include start-breakpoint($medium-m) {
          display: block;
        }
      }
    }
  }
}

.clinical-reality {
  &__product-video {
    &--video {
      .mobile_hidden {
        @include start-breakpoint($medium-m) {
          height: -webkit-fill-available;
        }
        @include start-breakpoint($medium-max) {
          max-height: inherit;
        }
      }
      .pc_hidden {
        @include start-breakpoint($small-m) {
          max-height: 500px;
        }
      }
    }
  }
}

.section-clinicalreality {
  .main {
    clear: both;
  }
  .mmuze-wrapper-btn {
    display: none;
  }
  .nav-promo {
    display: none;
  }
}

.clinical-reality__modules--recommended-products {
  .clinical-reality {
    &__recommended-products--carousel {
      top: 38%;
    }
  }
}
