.elc-product-full {
  .elc-vto-foundation {
    &-shade-grid {
      .elc-vto {
        &-custom-shade-picker-item-name {
          display: inline;
          white-space: normal;
        }
        &-perfect-shade-label {
          display: flex;
          font-size: 16px;
          justify-content: center;
          width: 70px;
        }
      }
    }
    .vto-foundation-perfect-shade {
      .slick-slide:nth-child(2) {
        flex-basis: 25%;
      }
    }
    &-shade-picker-wrapper {
      margin-bottom: 16px;
    }
    .elc-product-cta-wrapper {
      .elc-product-notify-me-button {
        font-size: 14px;
        height: 48px;
        line-height: normal;
      }
    }
  }
}
