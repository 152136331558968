/* Account signin */
#signin {
  #signin-block {
    .social-login {
      .social-login__email-opt-in {
        input[type='checkbox'] {
          height: 14px;
        }
        label {
          margin-#{$ldirection}: 0;
          color: $color-btn-dark-gray;
        }
      }
    }
  }
}
/* Checkout signin page */
#main {
  .checkout__panel {
    .social-login__email-opt-in {
      input {
        height: 18px;
        width: auto;
        @media #{$large-up} {
          height: 15px;
        }
      }
      label {
        margin-#{$ldirection}: 5px;
        color: $color-btn-dark-gray;
      }
      display: flex;
    }
    .social-login__divider {
      background-color: $color-white;
    }
    .social-login__terms {
      text-align: #{$ldirection};
      padding: 0;
    }
  }
}

.social-login {
  .social-login__email-opt-in {
    .social-login__opt-in-label {
      padding-#{$ldirection}: 0;
    }
  }
}

body#index {
  #main.single {
    .panel.edit {
      h2.social-login__divider {
        background-color: $color-white;
      }
    }
  }
}
/* Signin Page Button */
#sign-in-panel {
  .social-login {
    display: inline-block;
    &__email-opt-in,
    &__terms {
      @media #{$medium-up} {
        margin-#{$ldirection}: 15px;
      }
    }
  }
}
/* Order confirmation page */
#confirm,
.checkout-confirmation-page {
  .checkout__content {
    .social-login {
      border-top: 1px solid $color-shade-grey;
      margin-top: 20px;
      .social-login__container {
        @media #{$medium-up} {
          margin-#{$ldirection}: 13px;
        }
        text-align: #{$ldirection};
        margin-#{$ldirection}: 14px;
      }
      &__title {
        display: block;
      }
      &__terms {
        display: block;
      }
      &__info {
        margin-bottom: 15px;
        display: block;
      }
      .social-login__email-opt-in label {
        color: $color-btn-dark-gray;
      }
    }
  }
}
/* Account Profile Page */
.registration_content {
  .social-info {
    &__connect {
      .fb-login-button {
        margin-#{$ldirection}: 10px;
        span {
          padding-top: 5px;
        }
      }
    }
  }
}
/* Gnav */
.gnav-my-account-v1 {
  @media #{$xlarge-only} {
    height: 500px;
    overflow-y: scroll;
  }
  .social-login.gnav {
    .facebook-logo {
      display: none;
    }
  }
}
/* Confirmation Page */
#confirmation-page.checkout-confirmation-page {
  .social-login {
    .social-login__email-opt-in {
      input[type='checkbox'] {
        width: auto;
        height: auto;
        padding: 7px;
      }
    }
  }
}
